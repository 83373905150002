.downloadFooter-downloadAppContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px 15px 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0F0F0F;
  border-top-width: 1;
  border-top-style: solid;
  border-top-color: #222222;
}

.downloadFooter-downloadAppText {
  font-weight: 500;
  font-size: 14px;
  margin-right: 5px;
  color: gray;
}

.downloadFooter-textButton {
  color: #007AFF;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}
