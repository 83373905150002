.breathePage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 60px 175px 260px 175px;
}

.breathePage-breatheText {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 170px;
  text-align: center;
  color: lightgray;
}

.breathePage-breatheView {
  width: 50px;
  height: 50px;
  background-color: #007AFF;
  border-radius: 25px;
}
