.bottomBar-container {
  display: flex;
  flex-direction: row;
  padding: 20px 175px 20px 175px;
  justify-content: space-between;
  align-items: center;
  background-color: #0F0F0F;
  border-top-style: solid;
  border-top-color: #1F1F1F;
  border-top-width: 1.5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottomBar-subContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomBar-textFont {
  font-size: 18px;
  font-weight: 500;
  color: gray;
  white-space: nowrap;
}

.bottomBar-buttonFont {
  font-family: 'Avenir';
  font-size: 18px;
  font-weight: 500;
	color: gray;
	border: none;
	cursor: pointer;
  margin-left: 60px;
  text-decoration: none;
}

@media (max-width: 800px) {
  .bottomBar-container {
    padding: 20px 50px 20px 50px;
    justify-content: center;
  }
  .bottomBar-subContainer {
    width: 80%;
  }
  .bottomBar-textFont {
    display: none;
  }
  .bottomBar-buttonFont {
    margin-left: 0;
  }
}
