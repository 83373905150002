.chilltokPage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chilltokPage-videoplayer {
  
}
