.landingPage-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 175px 100px 175px;
  background-image: url('../../assets/images/backgroundImage.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.landingPage-subContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 50px;
}

.landingPage-mainText {
  font-size: 40px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
}

.landingPage-subText {
  font-size: 25px;
  font-weight: 500;
  width: 400px;
  color: #8E8E8E;
  margin-bottom: 25px;
}

.landingPage-downloadButtonsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.landingPage-downloadButton {
  margin-bottom: 30px;
  border: none;
  appearance: none;
  cursor: pointer;
  background-color: inherit;
}

.landingPage-downloadImage {
  height: 55px;
}

.landingPage-appScreenshotsImage {
  width: 550px;
}

@media (max-width: 1350px) {
  .landingPage-container {
    flex-direction: column;
  }
  .landingPage-mainText {
    text-align: center;
  }
  .landingPage-subText {
    text-align: center;
  }
  .landingPage-subContainer {
    align-items: center;
    margin-right: 0px;
  }
}

@media (max-width: 800px) {
  .landingPage-container {
    padding: 50px 50px 50px 50px;
  }
  .landingPage-mainText {
    font-size: 30px;
  }
  .landingPage-subText {
    font-size: 18px;
    width: 350px;
  }
  .landingPage-downloadImage {
    height: 45px;
  }
  .landingPage-appScreenshotsImage {
    width: 300px;
  }
}
